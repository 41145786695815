<template>
<div class="d-flex align-items-center">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-10 col-xl-5 my-5">
          
          <div class="card shadow-sm">
            <div class="row">
              <div class="col-md-12">
                <div class="card-body py-5">
                  <!-- Heading -->
                  <h1 class="display-4 text-center mb-3">
                    Masuk
                  </h1>
                  
                  <!-- Subheading -->
                  <p class="text-muted text-center mb-3">
                    Scola LMS | Data Master
                  </p>
                  
                  <!-- Form -->
                  <form v-on:submit.prevent="onSubmit">
                    <img src="https://assets.scola.id/frontend/images/login/scola-logo-new.svg"
                        alt="Logo Scola" width="110" class="form-logo d-block mx-auto mb-4">
                    <div class="d-block text-primary font-weight-bolder text-sm mb-3">Two Factor Authentication</div>
                    <div class="d-block p-3 bg-light rounded mb-3">
                        <div><span class="fe fe-user mr-2"></span>Hai, <span class="font-weight-bolder">{{user.name}}</span></div>
                    </div>

                    <div id="kondisi-memasukan-kode-autentikasi">
                        <p>Masukan kode dari aplikasi authentikasi Anda.</p>
                        <div>
                            <form v-on:submit.prevent="onSubmit">
                                <input type="hidden" name="scola_csrf_token" value="520e2457729c9d2c61b2b67290ee805f">
                                <!-- #### Input email -->
                                <input name="auth_key" type="text" placeholder="Masukan kode autentikasi"
                                    class="form-control text-center mb-3 bg-lighter" v-model="auth_key">

                                <!-- #### Alert jika kode autentikasi belum terisi (validasi form input) #### -->
                                <div class="alert alert-danger text-sm p-2 mb-3" v-if="!auth_key && submitted && $v.auth_key.$error"><i class="fe fe-info mr-2"></i>Mohon isi kode autentikasi anda</div>
                                <div class="alert d-none text-sm p-2 mb-3"><i class="fe fe-info mr-2"></i></div>

                                <!-- #### Alert jika kode autentikasi salah #### -->
                                <div class="alert alert-warning text-sm p-2 mb-3" v-if="!response.status"><i class="fe fe-info mr-2"></i>{{response.message}}</div>

                                <!-- #### Action #### -->
                                <button type="submit" class="btn btn-primary btn-block">Masuk ke Aplikasi</button>

                                <router-link to="/signout" class="btn btn-white btn-block">Kembali</router-link>

                                <!-- #### Kondisi loading kirim email setelah klik action diatas #### -->
                                <!-- <button type="button" class="btn btn-primary btn-block" disabled>
                                                                    <div class="spinner-border spinner-border-sm" role="status">
                                                                        <span class="sr-only">Loading...</span>
                                                                    </div>
                                                                </button> -->
                            </form>
                        </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            
        
            
          </div>
          

        </div>
      </div> <!-- / .row -->
    </div> <!-- / .container -->
</div>
</template>
<script>
  import { required } from "vuelidate/lib/validators";
  import axios from "axios";

  export default {
    name: 'Twofactor',
    metaInfo: {
      title: 'Twofactor',
      titleTemplate: '%s - Scola LMS | Data Master'
    },
    data(){
        return {
            auth_key : "",
            submitted: false,
            waiting: false,
            response: {status: true},
            user: {
              name: localStorage.getItem("name")
            }
        }
    },
    validations: {
          auth_key: { required },
    },
    mounted() {
      setTimeout(() => this.scrollFix(this.$route.hash), 1);
    },
    methods: {
        onSubmit: function () {
          this.waiting = true;
          this.submitted = true;

          // stop here if form is invalid
          this.$v.$touch();
          if (this.$v.$invalid) {
              this.$swal({
                  icon: 'warning',
                  title: 'Ups!',
                  text: 'Harap lengkapi form',
              });
              return;
          } else {
              this.$http.post(this.$apiconfig+'admin/auth/validate_twofactor', {
                  auth_key: this.auth_key,
                  email: localStorage.getItem('email')
              })
              .then(response => {
                  this.waiting = false;
                  this.response = response.data;
                  if (response.data.status) {
                      const token = response.data.key;
                      localStorage.setItem('auth_token', token);
                      axios.defaults.headers.common['Authorization'] = token;
                      this.$router.push('/admin/dashboard');
                  }
              })
              .catch(function (error) {
                  // Swal.fire('Uh oh!', 'Please check your internet connection.', 'error')
                  console.debug("Login issue: "+error)
              });
          }
        },
        scrollFix: function(hashbang)
        {
            location.hash = hashbang;

            // remove fragment as much as it can go without adding an entry in browser history:
            window.location.replace("#");

            // slice off the remaining '#' in HTML5:    
            if (typeof window.history.replaceState == 'function') {
                history.replaceState({}, '', window.location.href.slice(0, -1));
            }

        }
    }
  }
</script>